import * as React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CreateIcon from '@mui/icons-material/Create';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SessionNotes from './SessionNotes';
import SessionContent from './SessionContent';
import { Colors } from '../../../enums/enums';
import LearningTools from './learningTools/LearningTools';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FacilitatorProfile from './facilitators/FacilitatorProfile';

export default function Config() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', mt: 3 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="standard"
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: 'olive' },
            '& .MuiTab-root': { color: 'grey', width: '250px' },
            '& .Mui-selected': { color: Colors.Text }
          }}
        >
          <Tab value={1} label="Session Content" icon={<AttachFileIcon />} />
          <Tab value={2} label="Learning Tools" icon={<SchoolOutlinedIcon />} />
          <Tab value={3} label="Session Notes" icon={<CreateIcon />} />
          <Tab value={4} label="Facilitators" icon={<PermContactCalendarIcon />} />
        </Tabs>
      </Box>

      {value === 1 && <SessionContent />}
      {value === 2 && <LearningTools />}
      {value === 3 && <SessionNotes />}
      {value === 4 && <FacilitatorProfile />}
    </>
  );
}