export enum UserType {
  Mentees = 'mentees',
  Mentors = 'mentors',
  All = 'all',
  Coaching = 'coaching'
}

export enum SurveyTargetType {
  All = 'all',
  Mentees = 'mentees',
  Mentors = 'mentors',
  Coaches = 'coaches',
  Coachees = 'coachees'
}
// eslint-disable-next-line
export enum SurveyType {
  Onboarding = 'Onboarding',
  NewMenteeSurvey = 'New Mentee Survey',
  NewMentorSurvey = 'New Mentor Survey',
  NewCoacheeSurvey = 'New Coachee Survey',
  NewCoachSurvey = 'New Coach Survey',
  OnboardingMentee = 'Onboarding Mentee',
  OnboardingMentor = 'Onboarding Mentor',
  MeetAndGreetFeedback = 'Meet and Greet Feedback',
  FinalSurvey = 'Final Survey',
  TermsAndConditionsAgreement = 'Term and Conditions Agreement',
  MidCohortSurvey = 'Mid-Cohort Survey'
}

export enum UserRoleType {
  Mentor = 'Mentor',
  Mentee = 'Mentee',
  Coach = 'Coach',
  Coachee = 'Coachee',
  BasicSubscription = 'Basic Subscription'
}

export enum CoachingStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum UserStatus {
  New = 'NEW',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum GroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum ClientStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CoachingSessionStatus {
  Confirmed = 'CONFIRMED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum Colors {
  ButtonGreen = '#3C7441',
  Text = '#506657',
  TextElevated = '#6D8976',
  TextElevatedLighter = '#92b69d',
  BackgroundMain = '#F7FCF9',
  BackgroundDrawer = '#F0F9F1',
  BackgroundMainLighter = '#fcfdfc',
  MenuText = '#3C7441',
  SelectedDrawerBackground = '#E8F4E9',
  Active = '#E8F4E9',
  Inactive = '#F1DEE1'
}

export enum TaskName {
  FirstSessionAssessment = 'First Session Assessment',
  MidCohortGroupAssessment = 'Mid Cohort Group Assessment',
  FinalGroupAssessment = 'Final Group Assessment',
  PilotGroupCoachingAssessment = 'Pilot Group Coaching Assessment',
  OneOnOneFinalSurvey = 'One on One Final Survey'
}