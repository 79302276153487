import { Box, Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { FileObjectStatus } from '../config.enum';
import { Colors } from '../../../../enums/enums';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { IUser } from '../../../../interfaces/interfaces';

interface Props {
  open: boolean;
  fileObject: {
    id: string;
    filename: string;
    status: FileObjectStatus;
    title: string;
    azureUrl: string;
    thumbnailName: string;
  };
  facilitator: IUser;
  handleClose: () => void;
}

const PreviewFacilitatorProfile = (props: Props) => {
  const { open, fileObject, facilitator, handleClose } = props;

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            position: 'sticky',
            minHeight: '55px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
            backgroundColor: Colors.BackgroundDrawer,
            color: Colors.TextElevated,
            fontSize: '1.5rem',
            textAlign: 'center'
          }}
        >
          {facilitator?.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <img
              src={fileObject?.azureUrl}
              style={{
                maxWidth: '50%',
                maxHeight: 'auto',
                objectFit: 'cover',
                // objectPosition: 'center',
                boxShadow:
                  'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                borderRadius: 5,
                marginTop: '15px',
                marginBottom: '10px',
                marginLeft: '20px'
              }}
            />
            <Box>
              <Box sx={{ mx: 5, mt: 5 }}>
                {/*doing it through html, so that it preserves the breaks set by admin*/}
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: fileObject?.title }} />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: Colors.BackgroundDrawer }}>
          {facilitator?.linkedin && (
            <Button
              sx={{
                backgroundColor: Colors.TextElevatedLighter,
                border: 0,
                color: 'white',
                width: '200px',
                height: '30px',
                my: 1,
                mr: 2
              }}
              variant="contained"
              color="success"
              href={facilitator?.linkedin}
              target="_blank"
            >
              View LinkedIn Profile
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviewFacilitatorProfile;