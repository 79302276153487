import api from '../../services/api';
import { toast } from 'react-toastify';
import { FileObjectStatus } from '../../components/admin/config/config.enum';

export enum ContentActionTypes {
  FETCH = 'FILES_FETCH',
  FETCH_LEARNING_TOOLS = 'FILES_LEARNING_TOOLS',
  FETCH_FACILITATORS = 'FETCH_FACILITATORS',
  DELETE = 'DELETE_FILE',
  DELETE_LEARNING_TOOL = 'DELETE_LEARNING_TOOL',
  DELETE_FACILITATOR = 'DELETE_FACILITATOR',
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPDATE_FILE = 'UPDATE_FILE',
  UPDATE_FACILITATOR = 'UPDATE_FACILITATOR',
  UPLOAD_LEARNING_TOOL = 'UPLOAD_LEARNING_TOOL'
}

const baseUrl = '/content';

export function fetchContentFiles() {
  return function (dispatch) {
    return api.get(`${baseUrl}/file`).then(({ data }) => {
      dispatch({
        type: ContentActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchLearningTools() {
  return function (dispatch) {
    return api.get(`${baseUrl}/learning-tool`).then(({ data }) => {
      dispatch({
        type: ContentActionTypes.FETCH_LEARNING_TOOLS,
        payload: data
      });
    });
  };
}

export function deleteFile(fileId: string, usages: any) {
  return function (dispatch) {
    const url = `${baseUrl}/file/${fileId}`;
    return api
      .delete(url, {
        data: {
          usages
        }
      })
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;

        if (1 === data) {
          dispatch({
            type: ContentActionTypes.DELETE,
            payload: fileId
          });
          toast.success('Successfully deleted file');
        } else {
          toast.error(`Error deleting file.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error deleting file', error);
        toast.error(
          `Error deleting file.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export function deleteLearningTool(fileId: string) {
  return function (dispatch) {
    const url = `${baseUrl}/learning-tool/${fileId}`;
    return api
      .delete(url)
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;

        if (1 === data) {
          dispatch({
            type: ContentActionTypes.DELETE_LEARNING_TOOL,
            payload: fileId
          });
          toast.success('Successfully deleted file');
        } else {
          toast.error(`Error deleting file.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error deleting file', error);
        toast.error(
          `Error deleting file.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export function deleteFacilitator(id: string) {
  return function (dispatch) {
    const url = `${baseUrl}/facilitator/${id}`;
    return api
      .delete(url)
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;

        if (1 === data) {
          dispatch({
            type: ContentActionTypes.DELETE_FACILITATOR,
            payload: id
          });
          toast.success('Successfully deleted Facilitator Profile');
        } else {
          toast.error(`Error deleting fFacilitator Profile.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error deleting Facilitator Profile', error);
        toast.error(
          `Error deleting Facilitator Profile.\nMessage: ${
            error.response?.data?.message || error.request || error.message
          }`
        );
      });
  };
}

export function updateContent(
  id: string,
  title: string,
  status: FileObjectStatus,
  thumbnailName: string,
  thumbnailUrl: string,
  clientIds: string[]
) {
  return function (dispatch) {
    const url = `${baseUrl}/file/${id}`;
    const data = { title, status, thumbnailName, thumbnailUrl, clientIds };
    return api
      .patch(url, data)
      .then(({ data }) => {
        dispatch({
          type: ContentActionTypes.UPDATE_FILE,
          payload: data
        });
        toast.success('Successfully updated learning tool');
      })
      .catch((error) => {
        console.error('Error updating file', error);
        toast.error(
          `Error updating file.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export function saveFacilitator(id: string, title: string, status: FileObjectStatus, userId: string) {
  return function (dispatch) {
    const url = `${baseUrl}/facilitator/${id}`;
    const data = { title, status, userId };
    return api
      .patch(url, data)
      .then(({ data }) => {
        dispatch({
          type: ContentActionTypes.UPDATE_FACILITATOR,
          payload: data
        });
        toast.success('Successfully updated Facilitator Profile');
      })
      .catch((error) => {
        console.error('Error updating Facilitator Profile', error);
        toast.error(
          `Error updating Facilitator Profile.\nMessage: ${
            error.response?.data?.message || error.request || error.message
          }`
        );
      });
  };
}

export function fetchFacilitatorsProfiles() {
  return function (dispatch) {
    return api.get(`${baseUrl}/facilitator`).then(({ data }) => {
      dispatch({
        type: ContentActionTypes.FETCH_FACILITATORS,
        payload: data
      });
    });
  };
}

// selectors
export const getFiles = (state) => state.content.files;
export const getLearningTools = (state) => state.content.learningTools;
export const getFacilitators = (state) => state.content.facilitators;